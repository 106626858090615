import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Based on each brand’s voice guidelines, below you’ll find some different interactions that occur on a digital journey, with examples of how each brand might address them.`}</p>
    <p>{`These examples aren’t meant to be definitive or cast in stone. They’re an illustration to help you get each brand’s voice ‘in your ear’.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "when-a-user-completes-an-order",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#when-a-user-completes-an-order",
        "aria-label": "when a user completes an order permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`When a user completes an order`}</h2>
    <p>{`These examples cover the first message the user will see once they’ve completed a purchase journey.`}</p>
    <GridList columns="4" mdxType="GridList">
  <GridListItem title="Sainsbury's" titleElement="h3" border fontTheme="sainsburys" mdxType="GridListItem">
    Thanks for your order. We'll get right to it.
  </GridListItem>
  <GridListItem title="Argos" titleElement="h3" border fontTheme="argos" mdxType="GridListItem">
    All done. Your order’s on the way.
  </GridListItem>
  <GridListItem title="Habitat" titleElement="h3" border fontTheme="habitat" mdxType="GridListItem">
    Thanks. We'll get started on your order right away.
  </GridListItem>
  <GridListItem title="Nectar" titleElement="h3" border fontTheme="nectar" mdxType="GridListItem">
    Nicely done. That's 1000 points in the bag.
  </GridListItem>
    </GridList>
    <h2 {...{
      "id": "when-a-user-goes-to-log-in",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#when-a-user-goes-to-log-in",
        "aria-label": "when a user goes to log in permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`When a user goes to log in`}</h2>
    <p>{`These examples cover the header message that introduces a login screen or form to the user.`}</p>
    <GridList columns="4" mdxType="GridList">
  <GridListItem title="Sainsbury's" titleElement="h3" border fontTheme="sainsburys" mdxType="GridListItem">
    Welcome to Sainsbury's Groceries
  </GridListItem>
  <GridListItem title="Argos" titleElement="h3" border fontTheme="argos" mdxType="GridListItem">
    Do we know you?
  </GridListItem>
  <GridListItem title="Habitat" titleElement="h3" border fontTheme="habitat" mdxType="GridListItem">
    Already registered?
  </GridListItem>
  <GridListItem title="Nectar" titleElement="h3" border fontTheme="nectar" mdxType="GridListItem">
    Right, let's get started
  </GridListItem>
    </GridList>
    <h2 {...{
      "id": "when-a-user-is-looking-for-help",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#when-a-user-is-looking-for-help",
        "aria-label": "when a user is looking for help permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`When a user is looking for help`}</h2>
    <p>{`These examples cover the header message that introduces the help pages to the user.`}</p>
    <GridList columns="4" mdxType="GridList">
  <GridListItem title="Sainsbury's" titleElement="h3" border fontTheme="sainsburys" mdxType="GridListItem">
    What can we help you with today?
  </GridListItem>
  <GridListItem title="Argos" titleElement="h3" border fontTheme="argos" mdxType="GridListItem">
    How can we help?
  </GridListItem>
  <GridListItem title="Habitat" titleElement="h3" border fontTheme="habitat" mdxType="GridListItem">
    We're here to help
  </GridListItem>
  <GridListItem title="Nectar" titleElement="h3" border fontTheme="nectar" mdxType="GridListItem">
    Need some help?
  </GridListItem>
    </GridList>
    <h2 {...{
      "id": "when-the-user-searches-for-something-that-the-website-cant-find",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#when-the-user-searches-for-something-that-the-website-cant-find",
        "aria-label": "when the user searches for something that the website cant find permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`When the user searches for something that the website can't find`}</h2>
    <p>{`These are situations where a user has tried to search for something on a website, and the website is unable to return anything that matches their search term.`}</p>
    <GridList columns="4" mdxType="GridList">
  <GridListItem title="Sainsbury's" titleElement="h3" border fontTheme="sainsburys" mdxType="GridListItem">
    Sorry, we couldn't find any results under "ahahdfnjf"
  </GridListItem>
  <GridListItem title="Argos" titleElement="h3" border fontTheme="argos" mdxType="GridListItem">
    Did you mean that? We couldn't find anything for "ahahdfnjf”
  </GridListItem>
  <GridListItem title="Habitat" titleElement="h3" border fontTheme="habitat" mdxType="GridListItem">
    Sorry, there's no results for "ahahdfnjf". Want to try again?
  </GridListItem>
  <GridListItem title="Nectar" titleElement="h3" border fontTheme="nectar" mdxType="GridListItem">
    Hmm, we couldn't find anything for "ahahdfnjf"
  </GridListItem>
    </GridList>
    <h2 {...{
      "id": "when-you-want-to-update-a-user-about-a-delivery",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#when-you-want-to-update-a-user-about-a-delivery",
        "aria-label": "when you want to update a user about a delivery permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`When you want to update a user about a delivery`}</h2>
    <p>{`There are situations where you need to update a user about a delivery, usually by text or a push notification in an app.`}</p>
    <GridList columns="4" mdxType="GridList">
  <GridListItem title="Sainsbury's" titleElement="h3" border fontTheme="sainsburys" mdxType="GridListItem">
    Just to let you know, your order should be with you today at 13:31.
  </GridListItem>
  <GridListItem title="Argos" titleElement="h3" border fontTheme="argos" mdxType="GridListItem">
    Good news. Your order should be delivered to [address] at 13:31. We'll see
    you there.
  </GridListItem>
  <GridListItem title="Habitat" titleElement="h3" border fontTheme="habitat" mdxType="GridListItem">
    Time to get excited. Your Habitat order is on the way.
  </GridListItem>
  <GridListItem title="Nectar" titleElement="h3" border fontTheme="nectar" mdxType="GridListItem">
    Sorted. Your new Nectar card is on the way.
  </GridListItem>
    </GridList>
    <h2 {...{
      "id": "when-you-want-to-ask-a-user-for-feedback",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#when-you-want-to-ask-a-user-for-feedback",
        "aria-label": "when you want to ask a user for feedback permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`When you want to ask a user for feedback`}</h2>
    <p>{`There are situations where you might want to ask for feedback from the user, usually at the end of an experience.`}</p>
    <GridList columns="4" mdxType="GridList">
  <GridListItem title="Sainsbury's" titleElement="h3" border fontTheme="sainsburys" mdxType="GridListItem">
    Let us know what we could be doing better
  </GridListItem>
  <GridListItem title="Argos" titleElement="h3" border fontTheme="argos" mdxType="GridListItem">
    How are we doing?
  </GridListItem>
  <GridListItem title="Habitat" titleElement="h3" border fontTheme="habitat" mdxType="GridListItem">
    How are we doing?
  </GridListItem>
  <GridListItem title="Nectar" titleElement="h3" border fontTheme="nectar" mdxType="GridListItem">
    We want your feedback
  </GridListItem>
    </GridList>
    <h2 {...{
      "id": "when-you-want-to-say-hello-with-a-chatbot",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#when-you-want-to-say-hello-with-a-chatbot",
        "aria-label": "when you want to say hello with a chatbot permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`When you want to say hello with a chatbot`}</h2>
    <p>{`There are situations where users will be using a live chat service in order to talk to customer service teams. It’s important to make sure the way each brand says hello is friendly and true to that brand.`}</p>
    <GridList columns="4" mdxType="GridList">
  <GridListItem title="Sainsbury's" titleElement="h3" border fontTheme="sainsburys" mdxType="GridListItem">
    Hello, what can we help you with today?
  </GridListItem>
  <GridListItem title="Argos" titleElement="h3" border fontTheme="argos" mdxType="GridListItem">
    Hey there 👋, how can we help?
  </GridListItem>
  <GridListItem title="Habitat" titleElement="h3" border fontTheme="habitat" mdxType="GridListItem">
    Hey! How can we help?
  </GridListItem>
  <GridListItem title="Nectar" titleElement="h3" border fontTheme="nectar" mdxType="GridListItem">
    Hey! How can we help?
  </GridListItem>
    </GridList>
    <h2 {...{
      "id": "when-you-want-to-verify-a-users-email-address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#when-you-want-to-verify-a-users-email-address",
        "aria-label": "when you want to verify a users email address permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`When you want to verify a user’s email address`}</h2>
    <p>{`There are situations where we need to ask a user to do something in order to progress through an experience, like verifying an email address when signing up.`}</p>
    <GridList columns="4" mdxType="GridList">
  <GridListItem title="Sainsbury's" titleElement="h3" border fontTheme="sainsburys" mdxType="GridListItem">
    We just need to verify your email address
  </GridListItem>
  <GridListItem title="Argos" titleElement="h3" border fontTheme="argos" mdxType="GridListItem">
    Almost done! Just verify your email to continue
  </GridListItem>
  <GridListItem title="Habitat" titleElement="h3" border fontTheme="habitat" mdxType="GridListItem">
    Almost there. Verify your email address to continue
  </GridListItem>
  <GridListItem title="Nectar" titleElement="h3" border fontTheme="nectar" mdxType="GridListItem">
    One more thing. Verify your email and we're done
  </GridListItem>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "brand-voice-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#brand-voice-guidelines",
        "aria-label": "brand voice guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Brand voice guidelines`}</h2>
    <p>{`To further understand why our brands sound the way they do in these examples, we’ve also created voice guidelines for each of our brands, with an emphasis on digital interactions.`}</p>
    <GridList columns="2" mdxType="GridList">
  <GridListItem title="Sainsbury's" titleElement="h3" linkText="Read the Sainsbury's voice guidelines" linkTo="/copy/sainsburys-voice-guidelines/" filled="true" mdxType="GridListItem"></GridListItem>
  <GridListItem title="Argos" titleElement="h3" linkText="Read the Argos voice guidelines" linkTo="/copy/argos-voice-guidelines/" filled="true" mdxType="GridListItem"></GridListItem>
  <GridListItem title="Habitat" titleElement="h3" linkText="Read the Habitat voice guidelines" linkTo="/copy/habitat-voice-guidelines/" filled="true" mdxType="GridListItem"></GridListItem>
  <GridListItem title="Nectar" titleElement="h3" linkText="Read the Nectar voice guidelines" linkTo="/copy/nectar-voice-guidelines/" filled="true" mdxType="GridListItem"></GridListItem>
    </GridList>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions you have or help you with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      